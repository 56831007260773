import React, { useContext } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import favicon from "../images/icon.png";
import StoreContext from "context/Store";

const Head = ({ title, description, image }) => {
  const { defaultLogo } = useContext(StoreContext);
  const contentImage = image ?? defaultLogo
  const contentUrl = contentImage.startsWith('//') ? contentImage.replace("//", "https://") : contentImage

  return (
    <Helmet>
      <title>{title === "Home" ? `two : minds` : `${title} - two : minds`}</title>

      <meta name="description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta name="og:description" content={`${title} - two : minds`} />
      <meta name="og:title" content={title === "Home" ? `two : minds` : `${title} - two : minds`} />

      <meta property="og:image" content={contentUrl} />
      <link rel="icon" type="image/x-icon" href={favicon} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title === "Home" ? `two : minds` : `${title} - two : minds`} />
      <meta name="twitter:image" content={contentUrl} />
    </Helmet>
  );
};

export const seoImageFragment = graphql`
  fragment HeadPageModular on ContentfulPageModular {
    seoImage {
      default: gatsbyImageData
    }
    seoDescription {
      seoDescription
    }
  }
  fragment HeadPageRichText on ContentfulPageRichText {
    seoImage {
      default: gatsbyImageData
    }
    seoDescription {
      seoDescription
    }
  }
  fragment HeadPageAlphabeticalLinkList on ContentfulPageAlphabeticalLinkList {
    seoImage {
      default: gatsbyImageData
    }
    seoDescription {
      seoDescription
    }
  }
`;

export default Head;